import { FileExcelOutlined } from "@ant-design/icons";
import { message, Upload } from "antd";
import React from "react";
import UploadUtils from "../../../utils/UploadUtils";
import { __b } from "../../translations/i18n";

const ProfileUpload: React.FC = () => {
  const { Dragger } = Upload;

  const props = Object.assign(
    {
      multiple: true,
      name: "file",

      onChange: (info: any) => {
        const { status } = info.file;
        const errorsRow = info.file.response;
        if (status !== "uploading") {
          console.log(info.file, info.fileList);
        }
        
        switch(status){
          case 'done':
            message.success(__b("profiles.upload.success"));
            break
          case 'error':
            message.error(errorsRow.length >= 0 ? errorsRow : __b("profiles.upload.error"));
            break
        }
        
      },

      onDrop: (e: any) => {
        console.log("Dropped files", e.dataTransfer.files);
      },
    },
    UploadUtils.getUploadRequest("/profiles/import")
  );

  return (
    <Dragger {...props}>
      <p className="ant-upload-drag-icon">
        <FileExcelOutlined />{" "}
      </p>
      <p className="ant-upload-text"> {__b("profiles.upload.name")}</p>
      <p className="ant-upload-hint"> {__b("profiles.upload.label")}</p>
    </Dragger>
  );
};

export default ProfileUpload;