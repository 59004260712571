import { Component } from "react";
import DWEnvironmentManager from "../../../services/DWEnvironmentManager";
import { FormRow } from "../../../components/form/DynamicForm";
import { __b } from "../../translations/i18n";
import ObjectUtils from "../../utils/ObjectsUtil";
import CrudForm from "../../../components/CrudForm";
import { SaveOutlined } from "@ant-design/icons";

interface Props {
    module: string,
    add: (data: any) => any
}

interface State {
    roles: any[]
}

export default class ProfileAdd extends Component<Props, State> {

    provider = DWEnvironmentManager.getDataProvider ();

    getAddFormFields = (): FormRow[] => {

        return [
            {
                fields: [
                    {
                        name: 'roleId',
                        label: __b ( 'profiles.fields.profileName' ),
                        type: 'autocompleteselect',
                        required: false,
                        fetchOptions: ( key: string ) => ( this.fetchRoles ( key ) ),
                        colConf: {
                            span: 8
                        }
                    },
                    {
                        name: 'jobId',
                        label: __b ( 'profiles.fields.jobId' ),
                        type: 'text',
                        textType: 'number',
                        required: false,
                        colConf: {
                            span: 8
                        }
                    },
                    {
                        name: 'departmentId',
                        label: __b ( 'profiles.fields.departmentId' ),
                        type: 'text',
                        textType: 'number',
                        required: false,
                        colConf: {
                            span: 8
                        }
                    },
                    {
                        name: 'jobDescription',
                        label: __b ( 'profiles.fields.jobDescription' ),
                        type: 'text',
                        required: false,
                        colConf: {
                            span: 8
                        }
                    },
                    {
                        name: 'departmentDescription',
                        label: __b ( 'profiles.fields.departmentDescription' ),
                        type: 'text',
                        required: false,
                        colConf: {
                            span: 8
                        }
                    },
                    {
                        name: 'storeTypes',
                        label: __b ( 'profiles.fields.storeType' ),
                        type: 'select',
                        required: false,
                        mode: 'multiple',
                        selectValue: [
                            { label: __b ( "warranties.fields.values.all" ), value: '' },
                            { label: "Ownership", value: 'ownership' },
                            { label: "Wholesale", value: 'wholesale' },
                            { label: "Travel Retail", value: 'travel Retail' },
                            { label: "Franchisee", value: 'franchisee' },
                            { label: "Service Center", value: 'service Center' },
                            { label: "Family and Friends", value: 'family and friends' },
                            { label: "Vintage", value: 'vintage' },
                            { label: "Online", value: 'online' }
                        ],
                        colConf: {
                            span: 8
                        }
                    },
                    {
                        name: 'storeId',
                        label: __b ( 'profiles.fields.storeId' ),
                        type: 'autocompleteselect',
                        required: false,
                        mode: 'multiple',
                        fetchOptions: ( key: string ) => ( this.fetchStores ( key ) ),
                        colConf: {
                            span: 8
                        }
                    },
                    {
                        name: 'hierarchyId',
                        label: __b ( 'profiles.fields.dwArea' ),
                        type: 'autocompleteselect',
                        required: false,
                        mode: 'multiple',
                        fetchOptions: ( key: string ) => ( this.fetchHierarchies ( key ) ),
                        colConf: {
                            span: 8
                        }
                    },
                ]
            },
            {
                style: {
                    className: "buttonForm"
                },
                fields: [
                    {
                        name: 'addStore',
                        label: __b ( 'profiles.fields.add' ),
                        type: 'button',
                        htmlType: 'submit',
                        block: 'true',
                        icon: <SaveOutlined />,
                        colConf: { span: 24 }
                    }
                ]
            }
        ];
    }

    fetchHierarchies = async ( key: string ) => {
        return await fetchHierarchies ( key );
    }

    fetchStores = async ( key: string ) => {
        return await fetchStores ( key );
    }

    fetchRoles = async ( key: string ) => {
        return await fetchRoles ( key );
    }

    render() {
        const { module } = this.props
        return (
            <CrudForm module={ module } callback={ this.props.add } getFormFields={ this.getAddFormFields } cardSize={ 12 } type={"add"} /> )

    }


}

export const fetchHierarchies = async ( key: string ) => {

    const provider = DWEnvironmentManager.getDataProvider ();

    return provider.getList ( 20, 0, {
        params: {
            description: key,
            orderBy: 'DESCRIPTION'
        }
    }, 'hierarchies' )
        .then ( ( response ) =>
            response.map ( ( res: any ) => ( {
                label: res.description,
                value: res.id,
            } ) ),
        );
}

export const fetchStores = async ( key: string ) => {

    const provider = DWEnvironmentManager.getDataProvider ();
    const moduleManager = DWEnvironmentManager.getModuleManager ();
    const storeModule = moduleManager.getModule ( 'stores' );

    return provider.getList ( 20, 0, {
        params: {
            description: key,
            orderBy: 'DESCRIPTION'
        }
    }, storeModule.getModuleKey () )
        .then ( ( response ) =>
            response.map ( ( res: any ) => ( {
                label: `${ res.code } - ${ res.description }`,
                value: res.id,
            } ) ),
        );
}

export const fetchRoles = async ( key: string ) => {


    const provider = DWEnvironmentManager.getDataProvider ();

    return provider.getList ( 20, 0, {},
        'roles' )
        .then ( ( response ) =>
            ObjectUtils.stringIncludesDescriptionMapWithId ( response, key )
        );
}
