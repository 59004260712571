import { split } from "lodash";

class ObjectUtils {

    static stringIncludes = ( array: any, key: string ) => {
        let filtered = array.filter ( ( res: any ) => res.toUpperCase().includes ( key.toUpperCase() ) );

        return filtered.map ( ( res: any ) => ( {
            label: res,
            value: res
        } ) )
    }

    static stringIncludesWithOutLabel = ( array: any, key: string ) => {
        let filtered = array.filter ( ( res: any ) => res.toUpperCase().includes ( key.toUpperCase() ) );

        return filtered.map ( ( res: any ) => ( {
            label: res,
            value: split(res,'-')[0]
        } ) )
    }

    static stringIncludesDescription = ( array: any, key: string ) => {
        let filtered = array.filter ( ( res: any ) => res.description.toUpperCase().includes ( key.toUpperCase() ) );

        return filtered.map ( ( res: any ) => ( {
            label: res.description,
            value: res.description
        } ) )
    }

    static stringIncludesDescriptionMapWithId = ( array: any, key: string ) => {
        let filtered = array.filter ( ( res: any ) => res.description.toUpperCase().includes ( key.toUpperCase() ) );

        return filtered.map ( ( res: any ) => ( {
            label: res.description,
            value: res.id
        } ) )
    }
}

export default ObjectUtils;
