import { CheckCircleOutlined } from "@ant-design/icons";
import { Store } from "../../models/Store";
import DWEnvironmentManager from "../../services/DWEnvironmentManager";
import { addAction, addFilter } from "../../services/HooksManager";
import ParentModule from "../../shared/module/CrudModule";
import { __ } from "../../translations/i18n";
import BuEnvironmentManager from "../services/BuEnvironmentManager";
import buUserManager from "../services/BuUserManager";
import { __b } from "../translations/i18n";

class UserModule extends ParentModule {

    buEnvironmentManager: any;
    loadedUser: any;
    areaOptions: any[] = [];
    options = [
        { label: "Ownership", value: 'ownership' },
        { label: "Wholesale", value: 'wholesale' },
        { label: "Travel Retail", value: 'travel retail' },
        { label: "Franchisee", value: 'franchisee' },
        { label: "Service Center", value: 'service center' },
        { label: "Family and Friends", value: 'family and friends' },
        { label: "Vintage", value: 'vintage' },
        { label: "Online", value: 'online' }
    ];
    roles: any[] = [];
    roleUser: string | undefined;

    init = () => {
        this.buEnvironmentManager = BuEnvironmentManager;

        addFilter('users_table_search_form', this.addFieldsTosearch, 50);
        addFilter('users_table_columns', this.addToTableColumns, 50);
        addFilter('users_table_data_row', this.addDataToTableRow, 50);
        addFilter('crud_details_tabs_users', this.modifyEditModule);
        addFilter('crud_tabs_users', this.modifyTabs);
        addFilter('users_edit_form', this.editFieldsToEdit, 50);
        addFilter('users_edit_form_entity', this.loadEditUser);
        addFilter('users_edit_payload', this.editPayload);
        addFilter('users_add_payload', this.editPayload);
        addFilter('users_edit_on_change_state_fields', this.onChangeFilter);
        addFilter('users_add_on_change_state_fields', this.onChangeFilter);
        addAction('user_edit_loaded_roles', this.loadRoles);
        addAction('user_add_loaded_roles', this.loadRoles);
    }

    loadRoles = (roles: any) => {
        this.roles = roles;
    }

    modifyTabs = (tabsItems: any) => {
        const tabs = tabsItems;

        if (!buUserManager.userCanAddUsers()) {
            tabs.pop();
        }

        return tabs;
    }

    modifyEditModule = (tabsItems: any) => {
        const tabs = tabsItems;

        if (!buUserManager.userCanEditUsers()) {
            tabs.pop();
        }

        return tabs;
    }

    loadEditUser = async (entity: any) => {
        this.loadedUser = entity;
    }

    onChangeFilter = (formFields: any, changedFields: any) => {
        const changeIndex = changedFields.findIndex( (c: any) => c.name[0] === 'role');
        if (changeIndex !== -1) {
            const roleField = changedFields[changeIndex];
            let newRole = this.roles.find( r => r.item.code === roleField?.value?.value ?? roleField);

            if(newRole == null){
                newRole = this.roles.find( r => r.item.code === roleField?.value ?? roleField);
            }
            if(newRole == null){
                newRole = {label: this.loadedUser.role.code};
            }

            // cambio codice ruolo utente
            if (this.loadedUser) {
                this.loadedUser.role.code = newRole.label;
            }
            this.roleUser = newRole.label;

            // indici campi
            const hierarchiesFieldIndex = formFields[0].fields.findIndex((f: any) => f.name === 'hierarchies');
            const managedStoreFieldIndex = formFields[0].fields.findIndex((f: any) => f.name === 'stores');
            const storeChannelsFieldIndex = formFields[0].fields.findIndex((f: any) => f.name === 'storeChannels');

            //gestione campi da aggiungere o eliminare al cambio ruolo ->
            if (!this.showHierarchiesSelectRole(newRole.label)) {
                // eliminare campo hierarchies
                if (hierarchiesFieldIndex !== -1) {
                    formFields[0].fields.splice(hierarchiesFieldIndex, 1);
                }
            } else {
                // verificare se il campo non è già presente -> quindi aggiungerlo ai campi del form
                if (hierarchiesFieldIndex === -1) {
                    this.addHierarchiesField(formFields);
                }

            }

            if (!this.showStoresSelectRole(newRole.label)) {
                // eliminare campo stores managed
                if (managedStoreFieldIndex !== -1) {
                    formFields[0].fields.splice(managedStoreFieldIndex, 1);
                }
            } else {
                if (managedStoreFieldIndex === -1) {
                    this.addManagedStoreField(formFields);
                }

            }

            if (!this.showStoreTypeSelectRole(newRole.label)) {
                // eliminare campo store types
                if (storeChannelsFieldIndex !== -1) {
                    formFields[0].fields.splice(storeChannelsFieldIndex, 1);
                }
            } else {
                if (storeChannelsFieldIndex === -1) {
                    this.addStoreTypesField(formFields)
                }
            }
        }

        return formFields;
    }

    editFieldsToEdit = (fields: any[], module: any) => {
        if (this.showHierarchiesSelect()) {
            let options: any[] = [];
            if (this.loadedUser.hierarchies != null && this.loadedUser.hierarchies.length > 0) {
                options = this.loadedUser.hierarchies.map((hierarchy: any) => ({ label: hierarchy.description, value: hierarchy.id }));
            }

            this.addHierarchiesField(fields, options);
        }

        if (this.showStoresSelect()) {
            // managed stores
            let options: any[] = [];
            if (this.loadedUser.stores != null && this.loadedUser.stores.length > 0) {
                options = this.loadedUser.stores.map((store: any) => ({ label: `${store.code} - ${store.description}`, value: store.id }));
            }

            this.addManagedStoreField(fields, options);
        }

        if (this.showStoreTypeSelect()) {

            let initialValues: any[] = [];
            if (this.loadedUser.storeChannels != null && this.loadedUser.storeChannels.length > 0) {
                let initIndex: any[] = [];
                this.loadedUser.storeChannels.forEach((sCh: any) => {
                    let optI = this.options.findIndex(o => o.value === sCh);

                    if (optI !== -1) {
                        initIndex.push(optI);
                    }
                });

                initIndex.forEach(i => {
                    let obj = this.options[i];
                    initialValues.push(obj);
                });
            }

            this.addStoreTypesField(fields, initialValues);
        }

        return fields;
    }

    addHierarchiesField = (fields: any, initialValue?: any) => {
        fields[0]['fields'].push(
            {
                name: 'hierarchies',
                label: __('users.fields.area'),
                type: 'autocompleteselect',
                required: false,
                mode: 'multiple',
                fetchOptions: (key: string) => (this.fetchHierarchiesAdd(key)),
                filterOption: (input: any, option: any) => (option?.label?.toString() ?? '').toLowerCase().includes(input.toLowerCase()),
                initialValue: initialValue ?? undefined,
                colConf: {
                    span: 12
                }
            },
        );
    }

    addManagedStoreField = (fields: any, initialValue?: any) => {
        fields[0]['fields'].push(
            {
                name: 'stores',
                label: __b('users.fields.managedStores'),
                type: 'autocompleteselect',
                required: false,
                mode: 'multiple',
                fetchOptions: (key: string) => (this.fetchStoresAdd(key)),
                filterOption: (input: any, option: any) => (option?.label?.toString() ?? '').toLowerCase().includes(input.toLowerCase()),
                initialValue: initialValue ?? undefined,
                colConf: {
                    span: 12
                }
            },
        );
    }

    addStoreTypesField = (fields: any, initialValue?: any) => {
        fields[0]['fields'].push(
            {
                name: 'storeChannels',
                label: __b('users.fields.managedStoreTypes'),
                type: 'autocompleteselect',
                required: false,
                mode: 'multiple',
                initialValue: initialValue ?? undefined,
                fetchOptions: (key: any) => (this.fetchStoreTypesOptions(key)),
                colConf: {
                    span: 12
                }
            },
        );
    }

    fetchStoreTypesOptions = async (key: any) => {
        let storeTypesToReturn = [];
        for await (const opt of this.options) {
            if (opt.label.toLowerCase().includes(key.toLowerCase())) {
                storeTypesToReturn.push(opt);
            }
        }

        return storeTypesToReturn;
    }

    fetchOptions = (key: any, options: any) => {
        return options.find((item: any) => (item.vale.includes(key.toLowerCase())));
    }

    editPayload = async (data: any) => {
        const provider = DWEnvironmentManager.getDataProvider();
        let stores: any[] = [];
        let hierarchies: any[] = [];
        let storeChannels: any[] = [];

        if (!this.roleUser) {
            this.roleUser = this.loadedUser.role.code;
        }

        if (this.showStoresSelectRole(this.roleUser) && data.stores && data.stores.length > 0) {
            for await (const st of data.stores) {
                const store: Store = await provider.getOne(st.value ?? st, {}, 'stores');
                stores.push(store);
            }

            data.stores = stores;
        }

        if (this.showHierarchiesSelectRole(this.roleUser) && data.hierarchies && data.hierarchies.length > 0) {
            for await (const hr of data.hierarchies) {
                const hierarchy = await provider.getOne(hr.value ?? hr, {}, 'hierarchies');
                hierarchies.push(hierarchy);
            }

            data.hierarchies = hierarchies;
        }

        if (this.showStoreTypeSelectRole(this.roleUser) && data.storeChannels && data.storeChannels.length > 0) {
            for await (const stCh of data.storeChannels) {
                if (stCh.value) {
                    storeChannels.push(stCh.value);
                } else {
                    storeChannels.push(stCh);
                }

            }

            data.storeChannels = storeChannels;
        }

        return data;
    }

    getModuleKey = (): string => {
        return "bu-users";
    }

    addFieldsTosearch = (fields: any[], module: any) => {

        fields[0]['fields'].map((field: any) => {
            return field.colConf.span = 8;
        });


        fields[0]['fields'].map((field: any) => {
            // Override it
            if (field.name === "externalCode") {

                field.name = 'storeExternalCode';
                field.selectValue = [
                    { label: __b("warranties.fields.values.all"), value: '' },
                    { label: "Ownership", value: 'ownership' },
                    { label: "Wholesale", value: 'wholesale' },
                    { label: "Travel Retail", value: 'travel retail' },
                    { label: "Franchisee", value: 'franchisee' },
                    { label: "Service Center", value: 'service center' },
                    { label: "Family and Friends", value: 'family and friends' },
                    { label: "Vintage", value: 'vintage' },
                    { label: "Online", value: 'online' }
                ];
            }

            if (field.name === 'store'){
                field.fetchOptions =  (key: string) => (this.fetchStores(key))
            }

            return field;
        });

        fields[0]['fields'].push(
            {
                name: 'hierarchy',
                label: __('users.fields.area'),
                type: 'autocompleteselect',
                required: false,
                mode: 'multiple',
                fetchOptions: (key: string) => (this.fetchHierarchies(key)),
                colConf: {
                    span: 8
                }
            },
            {
                name: 'roleId',
                label: __('users.fields.role'),
                type: 'autocompleteselect',
                mode: 'multiple',
                required: false,
                fetchOptions: (key: string) => (fetchRolesByKey(key)),
                filterOption: (input: any, option: any) => (option?.label?.toString() ?? '').toLowerCase().includes(input.toLowerCase()),
                colConf: { span: 8 },
                allowClear: true
            }
        );

        return fields;
    }

    addToTableColumns = (cols: any[]) => {

        // removed override fields
        cols.splice(1, 3);

        cols.splice(1, 0,
            {
                title: __('users.columns.admin'),
                dataIndex: 'admin',
                key: 'admin',
                render: (admin: any) => {

                    return admin ? <CheckCircleOutlined style={{ color: "#AA8A5F", fontSize: 20 }} /> : <></>
                }
            },
            {
                title: __b('users.columns.role'),
                dataIndex: 'role',
                key: 'role'
            },
            {
                title: __('users.columns.personal'),
                dataIndex: 'personal',
                key: 'personal',
                render: (admin: any) => {
                    return admin ? <CheckCircleOutlined style={{ color: "#AA8A5F", fontSize: 20 }} /> : <></>
                }
            },
            {
                title: __('users.columns.exception'),
                dataIndex: 'exception',
                key: 'exception',
                render: (admin: any) => {
                    return admin ? <CheckCircleOutlined style={{ color: "#AA8A5F", fontSize: 20 }} /> : <></>
                }
            },
        )

        cols.splice(8, 0,
            {
                title: __b('users.columns.storeCode'),
                dataIndex: 'storeCode',
                key: 'storeCode'
            },
        )

        return cols
    }

    addDataToTableRow = (row: any, data: any) => {

        // Value to check are string and not boolean
        row['personal'] = data?.attributes?.personal === 'true' ? true : false;
        row['exception'] = data?.attributes?.ignored === 'true' ? true : false;

        row['store'] = data?.store?.description;
        row['storeCode'] = data?.store?.code;

        row['role'] = data?.role?.description;

        return row;
    }


    fetchHierarchies = async (key: string) => {
        return await fetchHierarchies(key);
    }

    fetchHierarchiesAdd = async (key: string) => {
        return await fetchHierarchiesAdd(key);
    }

    fetchStores = async (key: string) => {
        return await fetchStores(key);
    }

    fetchStoresAdd = async (key: string) => {
        return await fetchStoresAdd(key);
    }

    // for viewing area select -> after check administrator
    showHierarchiesSelect() {
        if (this.loadedUser !== undefined &&
            this.loadedUser.role !== undefined &&
            this.loadedUser.role !== null &&
            this.loadedUser.role.code !== undefined &&
            (this.loadedUser.role.code === 'country_manager' ||
                this.loadedUser.role.code === 'dos_manager' ||
                this.loadedUser.role.code === 'wholesales_manager' ||
                this.loadedUser.role.code === 'wholesales_admin_activations' ||
                this.loadedUser.role.code === 'wholesales_administrator' ||
                this.loadedUser.role.code === 'area_sales_executive' ||
                this.loadedUser.role.code === 'travel_retail_manager' ||
                this.loadedUser.role.code === 'after_sales_network_support' ||
                this.loadedUser.role.code === 'local_it' ||
                this.loadedUser.role.code === 'japan_ecommerce_logistics' ||
                this.loadedUser.role.code === 'ownership_manager' ||
                this.loadedUser.role.code === 'pre_activation_manager' ||
                this.loadedUser.role.code === 'retailer_manager' ||
                this.loadedUser.role.code === 'country_manager+bp' ||
                this.loadedUser.role.code === 'dos_manager+bp' ||
                this.loadedUser.role.code === 'wholesales_manager+bp' ||
                this.loadedUser.role.code === 'wholesales_admin_activations+bp' ||
                this.loadedUser.role.code === 'wholesales_administrator+bp' ||
                this.loadedUser.role.code === 'area_sales_executive+bp' ||
                this.loadedUser.role.code === 'travel_retail_manager+bp' ||
                this.loadedUser.role.code === 'after_sales_network_support+bp' ||
                this.loadedUser.role.code === 'local_it+bp' ||
                this.loadedUser.role.code === 'japan_ecommerce_logistics+bp' ||
                this.loadedUser.role.code === 'ownership_manager+bp' ||
                this.loadedUser.role.code === 'pre_activation_manager+bp' ||
                this.loadedUser.role.code === 'retailer_manager+bp')) {
            return true;
        }

        return false;
    };

    // for viewing select of store
    showStoresSelect() {
        if (this.loadedUser !== undefined &&
            this.loadedUser.role !== undefined &&
            this.loadedUser.role !== null &&
            this.loadedUser.role.code !== undefined &&
            (this.loadedUser.role.code === 'store_manager' ||
                this.loadedUser.role.code === 'deputy_store_manager' ||
                this.loadedUser.role.code === 'sales_person' ||
                this.loadedUser.role.code === 'sales_assistant' ||
                this.loadedUser.role.code === 'bulgari_store_after_sales' ||
                this.loadedUser.role.code === 'retailer_agent'
            || this.loadedUser.role.code === 'store_manager+bp' ||
                this.loadedUser.role.code === 'deputy_store_manager+bp' ||
                this.loadedUser.role.code === 'sales_person+bp' ||
                this.loadedUser.role.code === 'sales_assistant+bp' ||
                this.loadedUser.role.code === 'bulgari_store_after_sales+bp' ||
                this.loadedUser.role.code === 'retailer_agent+bp')) {
            return true;
        }

        return false;
    };

    // for viewing store type select
    showStoreTypeSelect() {
        if (this.loadedUser !== undefined &&
            this.loadedUser.role !== undefined &&
            this.loadedUser.role !== null &&
            this.loadedUser.role.code !== undefined &&
            (this.loadedUser.role.code === 'dos_manager' ||
                this.loadedUser.role.code === 'wholesales_administrator' ||
                this.loadedUser.role.code === 'wholesales_manager' ||
                this.loadedUser.role.code === 'wholesales_admin_activations' ||
                this.loadedUser.role.code === 'area_sales_executive' ||
                this.loadedUser.role.code === 'japan_ecommerce_logistics' ||
                this.loadedUser.role.code === 'pre_activation_manager' ||
                this.loadedUser.role.code === 'travel_retail_manager'
            || this.loadedUser.role.code === 'dos_manager+bp' ||
                this.loadedUser.role.code === 'wholesales_administrator+bp' ||
                this.loadedUser.role.code === 'wholesales_manager+bp' ||
                this.loadedUser.role.code === 'wholesales_admin_activations+bp' ||
                this.loadedUser.role.code === 'area_sales_executive+bp' ||
                this.loadedUser.role.code === 'japan_ecommerce_logistics+bp' ||
                this.loadedUser.role.code === 'pre_activation_manager+bp' ||
                this.loadedUser.role.code === 'travel_retail_manager+bp')) {
            return true;
        }

        return false;
    };

    // for viewing area select -> after check administrator
    showHierarchiesSelectRole(roleCode: any) {
        if (roleCode !== null &&
            roleCode !== undefined &&
            (roleCode === 'country_manager' ||
                roleCode === 'dos_manager' ||
                roleCode === 'wholesales_manager' ||
                roleCode === 'wholesales_admin_activations' ||
                roleCode === 'wholesales_administrator' ||
                roleCode === 'area_sales_executive' ||
                roleCode === 'travel_retail_manager' ||
                roleCode === 'after_sales_network_support' ||
                roleCode === 'local_it' ||
                roleCode === 'japan_ecommerce_logistics' ||
                roleCode === 'ownership_manager' ||
                roleCode === 'pre_activation_manager' ||
                roleCode === 'retailer_manager'
            || roleCode === 'country_manager+bp' ||
                roleCode === 'dos_manager+bp' ||
                roleCode === 'wholesales_manager+bp' ||
                roleCode === 'wholesales_admin_activations+bp' ||
                roleCode === 'wholesales_administrator+bp' ||
                roleCode === 'area_sales_executive+bp' ||
                roleCode === 'travel_retail_manager+bp' ||
                roleCode === 'after_sales_network_support+bp' ||
                roleCode === 'local_it+bp' ||
                roleCode === 'japan_ecommerce_logistics+bp' ||
                roleCode === 'ownership_manager+bp' ||
                roleCode === 'pre_activation_manager+bp' ||
                roleCode === 'retailer_manager+bp')) {
            return true;
        }

        return false;
    };

    // for viewing select of store
    showStoresSelectRole(roleCode: any) {
        if (roleCode !== null &&
            roleCode !== undefined &&
            (roleCode === 'store_manager' ||
                roleCode === 'deputy_store_manager' ||
                roleCode === 'sales_person' ||
                roleCode === 'sales_assistant' ||
                roleCode === 'bulgari_store_after_sales' ||
                roleCode === 'retailer_agent'
            || roleCode === 'store_manager+bp' ||
                roleCode === 'deputy_store_manager+bp' ||
                roleCode === 'sales_person+bp' ||
                roleCode === 'sales_assistant+bp' ||
                roleCode === 'bulgari_store_after_sales+bp' ||
                roleCode === 'retailer_agent+bp')) {
            return true;
        }

        return false;
    };

    // for viewing store type select
    showStoreTypeSelectRole(roleCode: any) {
        if (roleCode !== null &&
            roleCode !== undefined &&
            (roleCode === 'dos_manager' ||
                roleCode === 'wholesales_administrator' ||
                roleCode === 'wholesales_manager' ||
                roleCode === 'wholesales_admin_activations' ||
                roleCode === 'area_sales_executive' ||
                roleCode === 'japan_ecommerce_logistics' ||
                roleCode === 'pre_activation_manager' ||
                roleCode === 'travel_retail_manager'
            || roleCode === 'dos_manager+bp' ||
                roleCode === 'wholesales_administrator+bp' ||
                roleCode === 'wholesales_manager+bp' ||
                roleCode === 'wholesales_admin_activations+bp' ||
                roleCode === 'area_sales_executive+bp' ||
                roleCode === 'japan_ecommerce_logistics+bp' ||
                roleCode === 'pre_activation_manager+bp' ||
                roleCode === 'travel_retail_manager+bp')) {
            return true;
        }

        return false;
    };

}


export const fetchHierarchies = async (key: string) => {

    const provider = DWEnvironmentManager.getDataProvider();

    return provider.getList(20, 0, {
        params: {
            description: key,
            orderBy: 'DESCRIPTION'
        }
    }, 'hierarchies')
        .then((response) =>
            response.map((res: any) => ({
                label: res.description,
                value: res.code,
            })),
        );
}

export const fetchHierarchiesAdd = async (key: string) => {

    const provider = DWEnvironmentManager.getDataProvider();

    return provider.getList(20, 0, {
        params: {
            description: key,
            orderBy: 'DESCRIPTION'
        }
    }, 'hierarchies')
        .then((response) =>
            response.map((res: any) => ({
                label: res.description,
                value: res.id,
            })),
        );
}

export const fetchStores = async (key: string) => {

    const provider = DWEnvironmentManager.getDataProvider();
    const moduleManager = DWEnvironmentManager.getModuleManager();
    const storeModule = moduleManager.getModule('stores');

    return provider.getList(20, 0, {
        params: {
            description: key,
            orderBy: 'DESCRIPTION'
        }
    }, storeModule.getModuleKey())
        .then((response) =>
            response.map((res: any) => ({
                label: `${res.code} - ${res.description}`,
                value: `${res.code}-${res.description}`,
            })),
        );
}

export const fetchStoresAdd = async (key: string) => {

    const provider = DWEnvironmentManager.getDataProvider();
    const moduleManager = DWEnvironmentManager.getModuleManager();
    const storeModule = moduleManager.getModule('stores');

    return provider.getList(20, 0, {
        params: {
            description: key,
            orderBy: 'DESCRIPTION'
        }
    }, storeModule.getModuleKey())
        .then((response) =>
            response.map((res: any) => ({
                label: `${res.code} - ${res.description}`,
                value: res.id,
            })),
        );
}

export const fetchRolesByKey = async (key: any) => {

    const provider = DWEnvironmentManager.getDataProvider();
    let rolesArray: any[] = []
    await provider.getList(0, 0, {
        params: {
            description: key,
            orderBy: 'DESCRIPTION'
        }
    }, 'roles')
        .then((response) => {
            response.forEach((element: any) => {
                rolesArray.push({label: element.description, value: element.id, item: element})
            });

        });
    return rolesArray
}



const userModule = new UserModule();
export default userModule;
